import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchRegionsRequest } from "../../../redax/actions/regionsActions/regionsActions";
import { fetchAdminApplicationsListRequest } from '../../../redax/actions/adminApplicationsListActions/adminApplicationsListActions';
import { fetchAdminNewsListRequest } from '../../../redax/actions/adminNewsListActions/adminNewsListActions';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';

const AdminNews = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const adminNewsList = useSelector((state: RootState) => state.adminNewsList);
  const { regions  } = useSelector( (state: RootState) => state.regions);
  const [loadScreen, setLoadScreen] = useState(true);
  
  const [current_page, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState<any>(1);
  const [adminNewList, setAdminNewsList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  
  useEffect(() => {
    getApplications(1);
    dispatch(fetchRegionsRequest());
}, [dispatch]); 

const getApplications = (page: any) => {
    setLoadScreen(true);
    const data = {
        "page": page,
    }
        dispatch(fetchAdminNewsListRequest(data));
        if(adminNewsList.pagenation !== undefined) {
            setCurrentPage(adminNewsList.pagenation.current_page);
            setTotalPages(adminNewsList.pagenation.total_pages);
          }
}


  useEffect(() => {
    if (adminNewsList.pending) {
        setAdminNewsList(adminNewsList.newsArray);
        setCurrentPage(adminNewsList.pagenation.current_page);
        setTotalPages(adminNewsList.pagenation.total_pages);
        setTotalCount(adminNewsList.pagenation?.total_count || 1);
        setLoadScreen(false)
    }
  }, [adminNewsList]);

  const shaping_pagenation = () => {
    if (total_pages === null) return [1]; // Add a check for total_pages being null
    if (total_pages === 1) return [1]; // Add a check for total_pages being null
    
    if (total_pages <= 11) {
      return Array.from({ length: total_pages }, (_, index) => index + 1);
    } else if (current_page < 7) {
      return Array.from({ length: 11 }, (_, index) => index + 1);
    } else if (total_pages - current_page <= 5) {
      const diff_current_total = total_pages - current_page;
      const pagenation_start = current_page - (11 - (diff_current_total + 1));
      const pagenation_end = current_page + diff_current_total;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    } else {
      const pagenation_start = current_page - 5;
      const pagenation_end = current_page + 5;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    }
};




const pageDecrement = () => {
    if (page <= 1) {
        setPage(1);
        return;
    }
    setPage((prevPage) => prevPage - 1);
    setLoadScreen(true);
    getApplications(page-1)
}


const updatePage = (p: any) => {
    setPage(p);
    setLoadScreen(true);
    getApplications(p)
}


  const pageIncrement = () => {
    if (page >= total_pages) {
      setPage(total_pages);
      return;
    }
    setPage((prevPage) => prevPage + 1);
    setLoadScreen(true);
    getApplications(page+1)
  }
  function formatDate (value: any) {
    return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
}


  
  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">お知らせ一覧 </h2>
          </div>   
          <div className="body">
            <div className="adminTable1Wrapper">
              <div className="header">
                <div className="textWrapper">
                  <span className="text">{current_page}/{total_pages} ページ &nbsp;  全{total_count}件</span>
                </div>
                <div>
                  <button className='newsBtnBig' onClick={()=> navigate("/admin/news/new")}>新規作成</button>
                </div>
              </div>
              <div className="body">
              
                <table className="table openTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>リンク先</th>
                      <th>日時</th>
                      <th>タイトル</th>
                      <th>ターゲット</th>
                      <th style={{textAlign:'center'}}>区分 </th>
                    </tr>
                  </thead>
                  <tbody>
                  {adminNewList.length != 0 && adminNewList ?
                  <>
                  {adminNewList.map((adminList: any, index: any) => (
                    <tr className='openTable'>
                      <td>{index +1}</td>
                      <td>{adminList.url}</td>
                      <td>{formatDate(adminList.created_at)}</td>
                      {/* <td>{ adminList.target_type_name }</td> */}
                      <td>{ adminList.title }</td>
                      {adminList.target ?
                      <td>
                        <>
                          {adminList.target_type == 1 ? <a href={'/applicants/' + adminList.target.id + '/'}>{adminList.target.name}</a> : <></>}
                          {adminList.target_type == 2 ? <a href={'/applicants/' + adminList.target.id + '/'}>{adminList.target.name}</a> : <></>}
                        </>
                      </td> : <td></td>}
                      <td style={{textAlign:'center'}}>
                        {adminList.target_type_name === "企業" ? <p className='newsBtnBlue'>{adminList.target_type_name}</p> : <></>}
                        {adminList.target_type_name === "求職者" ? <p className='newsBtnOrange'>{adminList.target_type_name}</p> : <></>}
                      </td>
                    </tr>    
                  ))}
                  </>
                  : <></>}
                  </tbody>
                </table>
                <div className="paginationWrapper">
                    <div className="icon"></div>
                    <ul className="pagination">
                        {current_page && current_page > 1 && (
                        <li className="arrow prev">
                            <a onClick={() => pageDecrement()}>
                            <img src="/assets/img/company/icon06.svg" alt="前へ" />
                            </a>
                        </li>
                        )}

                        {shaping_pagenation().map((n: any) => (
                        <li key={n} className={n === current_page ? 'active' : ''}>
                            <a onClick={() => updatePage(n)}>{n}</a>
                        </li>
                        ))}

                        {current_page && current_page < total_pages && (
                        <li className="arrow next">
                            <a onClick={() => pageIncrement()}>
                            <img src="/assets/img/company/icon07.svg" alt="次へ" />
                            </a>
                        </li>
                        )}
                    </ul>
                </div>
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  );
};

export default AdminNews;
