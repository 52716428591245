function Contact() {
    return (
        <main id="contact">
        <div className="wrapper">
          <div className="list_box">
            <p><img src="assets/img/top/mv_ttl.svg" alt="どこで働きたい？"/></p>
            <div>
              <ul id="region">
                <li><a href="/recruitments/">全国</a></li>
                <li><a href="/recruitments?region_ids[]=1">北海道</a></li>
                <li><a href="/recruitments?region_ids[]=2">東北</a></li>
                <li><a href="/recruitments?region_ids[]=3">関東</a></li>
                <li><a href="/recruitments?region_ids[]=4">中部</a></li>
                <li><a href="/recruitments?region_ids[]=5">近畿</a></li>
                <li><a href="/recruitments?region_ids[]=6">中国</a></li>
                <li><a href="/recruitments?region_ids[]=7">四国</a></li>
                <li><a href="/recruitments?region_ids[]=8">九州</a></li>
              </ul>
            </div>
          </div>
        </div>
        <h2>お問い合わせ</h2>
    <p className="read">パラちゃんねるのご利用や操作方法について<br className="sp_only"/>詳しく知りたい方は使い方ガイドをご覧ください。</p>

    <p className="guide_bnr"><a href="/guide/"><img src="/assets/img/contact_bnr.png" alt="簡単！使い方ガイドはこちら"/></a></p>

    <div className="mail wrapper">
      <p className="read">
        「データが正しく登録出来ない」、「操作がフリーズしてしまう」など、サイトやシステムの不具合に<br className="pc_only"/>関するご報告・ご相談については、お手数ですが下記よりご連絡ください。
      </p>
      <h3>メールでのお問い合わせ</h3>

      <div className="mail_area">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeDLJEsGIDxz6BPA5ZqaTVZ4SOynQTLuzwseSaThKl3dVthxQ/viewform?embedded=true" width="100%" height="60vh">読み込んでいます…</iframe>
      </div>
    </div>
      </main>
    );
  }
  
  export default Contact;