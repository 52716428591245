import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { SetStateAction, useEffect, useState } from "react";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { fetchRegionsRequest } from "../../../../redax/actions/regionsActions/regionsActions";
import { fetchCompaniesRequest } from "../../../../redax/actions/companiesActions/companiesActions";
import { useParams } from "react-router-dom";
import { fetchPostCodeRequest } from "../../../../redax/actions/postcodeActions/postcodeActions";
import { fetchRecruitmentsListRequest } from "../../../../redax/actions/recruitmentsListActions/recruitmentsListActions";
import Loader from "../../../loader/loader";

export default function RecruiterRecruitmentsCreateStep_1() { 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const loginData = useSelector((state: RootState) => state.login);
    const { companies } = useSelector((state: RootState) => state.companies);
    const postcodeData = useSelector((state: RootState) => state.postcode);
    const recruitmentsData = useSelector((state: RootState) => state.recruitmentsList);
    const [helloworkNotice, setHelloworkNotice] = useState(false);
    const [isAnnualIncomeShow, setIsAnnualIncomeShow] = useState(true);
    const [restTime, setRestTime] = useState(null);
    const [numberOfWorkingDays, setNumberOfWorkingDays] = useState<any>(null);
    const [selectedSalaryFormId, setSelectedSalaryFormId] = useState('');
    const [lowerMonthlyBasicSalary, setLowerMonthlyBasicSalary] = useState(null);
    const [upperMonthlyBasicSalary, setUpperMonthlyBasicSalary] = useState(null);
    const [lowerEstimatedAnnualIncome, setLowerEstimatedAnnualIncome] = useState(null);
    const [retirementMoneyExists, setRetirementMoneyExists] = useState<any>(null);
    const [numberOfEmployees, setNumberOfEmployees] = useState(null);
    const [locationDepartment, setLocationDepartment] = useState(null);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [params, setAllParams] = useState<any>(null);
    const [recruitments, setRecruitments] = useState<any>([]);
    const [pages, setPages] = useState<any>(null);
    const { regions  } = useSelector((state: RootState) => state.regions);
    const [master, setMaster] = useState<any[]>([]); 
    const [jobCategories, setjobCategories] = useState<any[]>([]); 
    const [jobCategoryTypes, setjobCategoryTypes] = useState<any[]>([]);
    const [employmentStatuses, setemploymentStatuses] = useState<any[]>([]);
    const [typeOfRecruitingCompanies, settypeOfRecruitingCompanies] = useState<any[]>([]);
    const [contractPeriods, setcontractPeriods] = useState<any[]>([]);
    const [trialPeriods, settrialPeriods] = useState<any[]>([]);
    const [holidays, setholidays] = useState<any[]>([]);
    let company: any = [];
    const [companyName, setCompanyName] = useState('');
    const [salaryForms, setsalaryForms] = useState<any[]>([]);
    const [allowances, setAllowances] = useState<any[]>([]);
    const [selectedAllowanceIds, setSelectedAllowanceIds] = useState<any[]>([]);
    const [vacations, setvacations] = useState<any[]>([]);
    const [socialInsurances, setsocialInsurances] = useState<any[]>([]);
    const [selectionMethods, setselectionMethods] = useState<any[]>([]);
    const [work_from_home, setwork_from_home] = useState<any[]>([]);
    const jobTitlemaxLength = 20;
    const businessContentmaxLength = 400;
    const requiredExperiencemaxLength = 200;
    const locationWorkLocationSupplementalmaxLength = 200;
    const workTimeSupplementalmaxLength = 200;
    const restTimeSupplementalmaxLength = 200;
    const overtimeHoursSupplementalmaxLength = 200;
    const numberOfWorkingDaysSupplementalmaxLength = 200;
    const holidaysSupplementalmaxLength = 200;
    const allowancesSupplementalmaxLength = 200;
    const benefitSupplementalmaxLength = 200;
    const contractPeriodSupplementalmaxLength = 200;
    const trialPeriodSupplementalmaxLength = 200;
    const selectionMethodSupplementalmaxLength = 400;
    const locationWorkLocationSupplementPlaceholder = '「新宿支店、大宮支店でも応募可能です」など\n「原則在宅勤務、月一回のみ出社可能性あり」など';
    const [jobTitle, setJobTitle] = useState('');
    const [businessContent, setBusinessContent] = useState('');
    const [requiredExperience, setRequiredExperience] = useState('');
    const [locationWorkLocationSupplemental, setlocationWorkLocationSupplemental] = useState('');
    const [contractPeriodSupplemental, setcontractPeriodSupplemental] = useState('');
    const [trialPeriodSupplemental, settrialPeriodSupplemental] = useState('');
    const [workTimeSupplemental, setworkTimeSupplemental] = useState('');
    const [restTimeSupplemental, setrestTimeSupplemental] = useState('');
    const [numberOfWorkingDaysSupplemental, setnumberOfWorkingDaysSupplemental] = useState('');
    const [overtimeHoursSupplemental, setovertimeHoursSupplemental] = useState('');
    const [holidaysSupplemental, setholidaysSupplemental] = useState('');
    const [allowancesSupplemental, setallowancesSupplemental] = useState('');
    const [benefitSupplemental, setbenefitSupplemental] = useState('');
    const [selectionMethodSupplemental, setselectionMethodSupplemental] = useState('');
    const [workFromHomeId, setWorkFromHomeId] = useState<any>(null);
    const [HideAddressDetail, setHideAddressDetail] = useState(true);
    const [isContractPeriodShow, setIsContractPeriodShow] = useState(true);
    const [isContractPeriodExistsShow, setIsContractPeriodExistsShow] = useState<any>(null);
    const [areaText, setAreaText] = useState('');
    const [prefectureText, setPrefectureText] = useState('');
    const [cityText, setCityText] = useState('');
    const [postCode, setPostCode] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
    const [locationRegionId, setLocationRegionId] = useState(null);
    const [locationPrefectureId, setLocationPrefectureId] = useState(null);
    const [locationCityId, setLocationCityId] = useState(null);
    const [btn, setBtn] = useState(false);
    const [prefecture_name, setPrefectureName] = useState(String);
    const [disabled_address, setDisabledAddress] = useState(String);
    const [city_name, setCityName] = useState(String);
    const [isEditCityCorrection, setisEditCityCorrection] = useState<any>(false);
    const [cityCorrection, setCityCorrection] = useState('');
    const [isTrialPeriodExistsShow, setisTrialPeriodExistsShow] = useState<any>(null);
    const [address, setAddress] = useState(String);
    const [locationNearestStation, setLocationNearestStation] = useState('');
    const [locationTransportation, setLocationTransportation] = useState('');
    const [nationwide, setNationwide] = useState(false);
    const [startTime1Hour, setStartTime1Hour] = useState(null);
    const [startTime1Minute, setStartTime1Minute] = useState(null);
    const [startTime2Hour, setStartTime2Hour] = useState(null);
    const [startTime2Minute, setStartTime2Minute] = useState(null);
    const [startTime3Hour, setStartTime3Hour] = useState(null);
    const [startTime3Minute, setStartTime3Minute] = useState(null);
    const [endTime1Hour, setEndTime1Hour] = useState(null);
    const [endTime1Minute, setEndTime1Minute] = useState(null);
    const [endTime2Hour, setEndTime2Hour] = useState(null);
    const [endTime2Minute, setEndTime2Minute] = useState(null);
    const [endTime3Hour, setEndTime3Hour] = useState(null);
    const [endTime3Minute, setEndTime3Minute] = useState(null);
    const [startTime1, setStartTime1] = useState<any>(null);
    const [startTime2, setStartTime2] = useState<any>(null);
    const [startTime3, setStartTime3] = useState<any>(null);
    const [endTime1, setEndTime1] = useState<any>(null);
    const [endTime2, setEndTime2] = useState<any>(null);
    const [endTime3, setEndTime3] = useState<any>(null);
    const [workingDays, setWorkingDays] = useState<any[]>([1, 2, 3, 4, 5, 6]);
    const [contractRenewalExists, setContractRenewalExists] = useState<any>(null);
    const [fullTimeEmployeeExists, setFullTimeEmployeeExists] = useState<any>(null);
    const [selectedTrialPeriodId, setSelectedTrialPeriodId] = useState(null);
    const [overtimeHoursExists, setOvertimeHoursExists] = useState<any>(null);
    const [selectedVacationIds, setSelectedVacationIds] = useState<any>([]);
    const [annualHolidayCount, setAnnualHolidayCount] = useState(null);
    const [annualNumberOfBonusExists, setAnnualNumberOfBonusExists] = useState<any>(null);
    const [annualNumberOfBonus, setAnnualNumberOfBonus] = useState(null);
    const [annualNumberOfRaisesExists, setAnnualNumberOfRaisesExists] = useState<any>(null);
    const [annualNumberOfRaises, setAnnualNumberOfRaises] = useState(null);
    const [upperEstimatedAnnualIncome, setUpperEstimatedAnnualIncome] = useState(null);
    const [retirementAge, setRetirementAge] = useState('');
    const [selectedJobCategoryId, setSelectedJobCategoryId] = useState<any>(null);
    const [selectedJobTypeId, setSelectedJobTypeId] = useState<any>(null);
    const [typeOfRecruitingCompanyId, setTypeOfRecruitingCompanyId] = useState<any>(null);
    const [selectedEmploymentStatusId, setSelectedEmploymentStatusId] = useState('');
    const [selectedHolidayIds, setSelectedHolidayIds] = useState<any[]>([]);
    const [selectedSelectionMethodIds, setSelectedSelectionMethodIds] = useState<any[]>([]);
    const [selectedSocialInsuranceIds, setSelectedSocialInsuranceIds] = useState<any[]>([]);    
    const [selectedContractPeriodId, setSelectedContractPeriodId] = useState<any>(null);
    const [loadScreen, setLoadScreen] = useState(true);
    const [isData, setIsData] = useState(false);
    const [isStartMinute1Disable, setIsStartMinute1Disable] = useState(true);
    const [isStartMinute2Disable, setIsStartMinute2Disable] = useState(true);
    const [isStartMinute3Disable, setIsStartMinute3Disable] = useState(true);
    const [isEndMinute1Disable, setIsEndMinute1Disable] = useState(true);
    const [isEndMinute2Disable, setIsEndMinute2Disable] = useState(true);
    const [isEndMinute3Disable, setIsEndMinute3Disable] = useState(true);  
    const { id } = useParams();
    const location = useLocation();
    const data = location.state;
    const [isReload, setIsReload] = useState<any>(false);
    const [checkCopyOnCompanyAddress, setCheckCopyOnCompanyAddress] = useState<any>(false);

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''));
        window.scrollTo(0, 0);
        } else {
            navigate('/recruiter/login/')
        }

        if(nationwide == null){
            setNationwide(false);
          }
    }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setCompanyId(loginData.login.company.id);
            dispatch(fetchCompaniesRequest(loginData.login.company.id, 'get', null));
            dispatch(fetchRegionsRequest());
        }
      }, [dispatch, loginData.pending]);

    useEffect(() => {
        if(regions !== undefined) {
            setMaster(regions);            
            setjobCategoryTypes(regions.job_category_types);
            setemploymentStatuses(regions.employment_statuses);
            settypeOfRecruitingCompanies(regions.type_of_recruiting_companies);
            setcontractPeriods(regions.contract_periods);
            setholidays(regions.holidays);
            setsalaryForms(regions.salary_forms);
            setAllowances(regions.allowances);
            setvacations(regions.vacations);
            setsocialInsurances(regions.social_insurances);
            setselectionMethods(regions.selection_methods);
            setwork_from_home(regions.work_from_home);
            settrialPeriods(regions.trial_periods);

            if(data) {
                setHelloworkNotice(true);
                setJobTitle(data.jobTitle);
                setSelectedJobTypeId(data.jobTypeId)
                setSelectedJobCategoryId(data.jobCategoryId)
                //紐づくデータにする
                if(regions.job_categories != undefined) {
                    setjobCategories(regions.job_categories.filter((category: any) => category.job_category_type_id == data.jobTypeId))
                }
                setCompanyId(data.companyId)
                // setCompanyName(data.companyName)
                setSelectedEmploymentStatusId(data.employmentStatusId)
                setBusinessContent(data.businessContent)
                setRequiredExperience(data.requiredExperience)
                if(data.postCode) {
                    setPostCode(data.postCode)
                    dispatch(fetchPostCodeRequest(data.postCode));
                }                
                setTypeOfRecruitingCompanyId(data.typeOfRecruitingCompanyId);
                setLocationRegionId(data.locationRegionId)
                setLocationPrefectureId(data.locationPrefectureId)
                setAreaText(data.locationPrefectureName + data.locationCityName)
                setPrefectureText(data.locationPrefectureName)
                setCityText(data.locationCityName)
                setLocationCityId(data.locationCityId)
                setLocationAddress(data.locationAddress)
                setLocationDepartment(data.locationDepartment)
                setLocationNearestStation(data.locationNearestStation)
                setLocationTransportation(data.locationTransportation)
                setlocationWorkLocationSupplemental(data.locationWorkLocationSupplemental)
                setSelectedContractPeriodId(data.contractPeriodId)
                if(data.contractPeriodExists != null) {
                    setIsContractPeriodExistsShow(data.contractPeriodExists);
                }
                setContractRenewalExists(data.contractRenewalExists)
                setFullTimeEmployeeExists(data.fullTimeEmployeeExists)
                setcontractPeriodSupplemental(data.contractPeriodSupplemental)
                setisTrialPeriodExistsShow(data.trialPeriodExists)
                setSelectedTrialPeriodId(data.trialPeriodId)
                settrialPeriodSupplemental(data.trialPeriodSupplemental)
                if(data.startTime1) {
                    let start1 = data.startTime1.split(':');
                    setStartTime1Hour(start1[0]);
                    setStartTime1Minute(start1[1]);
                    setStartTime1(data.startTime1);
                    setIsStartMinute1Disable(false);
                }
                if(data.endTime1) {
                    let end1 = data.endTime1.split(':');
                    setEndTime1Hour(end1[0]);
                    setEndTime1Minute(end1[1]);
                    setEndTime1(data.endTime1);
                    setIsEndMinute1Disable(false);
                }
                if(data.startTime2) {
                    let start2 = data.startTime2.split(':');
                    setStartTime2Hour(start2[0]);
                    setStartTime2Minute(start2[1]);
                    setStartTime2(data.startTime2);
                    setIsStartMinute2Disable(false);
                }
                if(data.endTime2) {
                    let end2 = data.endTime2.split(':');
                    setEndTime2Hour(end2[0]);
                    setEndTime2Minute(end2[1]);
                    setEndTime2(data.endTime2);
                    setIsEndMinute2Disable(false);
                }
                if(data.startTime3) {
                    let start3 = data.startTime1.split(':');
                    setStartTime3Hour(start3[0]);
                    setStartTime3Minute(start3[1]);
                    setStartTime3(data.startTime3);
                    setIsStartMinute3Disable(false);
                }
                if(data.endTime3) {
                    let end3 = data.endTime3.split(':');
                    setEndTime2Hour(end3[0]);
                    setEndTime2Minute(end3[1]);
                    setEndTime3(data.endTime3);
                    setIsEndMinute3Disable(false);
                }
                setworkTimeSupplemental(data.workTimeSupplemental)
                setRestTime(data.restTime)
                setrestTimeSupplemental(data.restTimeSupplemental)
                setNumberOfWorkingDays(data.numberOfWorkingDays)
                setnumberOfWorkingDaysSupplemental(data.numberOfWorkingDaysSupplemental)
                setOvertimeHoursExists(data.overtimeHoursExists)
                setovertimeHoursSupplemental(data.overtimeHoursSupplemental)
                setSelectedHolidayIds(data.holidayIds)
                setAnnualHolidayCount(data.annualHolidayCount)
                setSelectedVacationIds(data.vacationIds)
                setholidaysSupplemental(data.holidaysSupplemental)
                setSelectedSalaryFormId(data.salaryFormId)
                setLowerMonthlyBasicSalary(data.lowerMonthlyBasicSalary)
                setUpperMonthlyBasicSalary(data.upperMonthlyBasicSalary)
                setAnnualNumberOfBonusExists(data.annualNumberOfBonusExists)
                setAnnualNumberOfBonus(data.annualNumberOfBonus)
                setLowerEstimatedAnnualIncome(data.lowerEstimatedAnnualIncome)
                setUpperEstimatedAnnualIncome(data.upperEstimatedAnnualIncome)
                setAnnualNumberOfRaisesExists(data.annualNumberOfRaisesExists)
                setAnnualNumberOfRaises(data.annualNumberOfRaises)
                setallowancesSupplemental(data.allowancesSupplemental)
                setSelectedAllowanceIds(data.allowanceIds)
                setRetirementMoneyExists(data.retirementMoneyExists)
                setRetirementAge(data.retirementAge)
                setSelectedSocialInsuranceIds(data.socialInsuranceIds)
                setSelectedSelectionMethodIds(data.selectionMethodIds)
                setbenefitSupplemental(data.benefitSupplemental)
                setselectionMethodSupplemental(data.selectionMethodSupplemental)
                setIsAnnualIncomeShow(data.salaryFormId == '1' ? true : false)
                setNumberOfEmployees(data.numberOfEmployees);
                setIsData(true);
            }
            setLoadScreen(false);
        }  
    },[regions, master]);

    useEffect(() => {
        if(companies.company !== undefined) {        
            company = companies.company;
            setCompanyName(company.name);
            setAddress(company.address);
        }
    }, [companies]);

    function onCheckCopyOnCompanyAddress (e: any) {
        let checkInfo = e.target.checked;
        if(checkInfo == false) {
            setCheckCopyOnCompanyAddress(false); 
        } else {
            setCheckCopyOnCompanyAddress(true); 
        }     
        if(companies.company !== undefined){
            const newAreaText = companies.company.prefecture.name + companies.company.city.name;
            setAreaText(newAreaText);
            setPrefectureText(companies.company.prefecture.name);
            setCityText(companies.company.city.name);
            setLocationRegionId(companies.company.prefecture.region_id);
            setLocationPrefectureId(companies.company.prefecture.id);
            setLocationCityId(companies.company.city.id);
            setPostCode(companies.company.postcode);
            setLocationAddress(companies.company.address);
        }
    }

    function onCityCorrection (e: any) {
        setCityCorrection(e.target.value);    
    }

      useEffect(() => {
        if (postcodeData.pending) {
            setLocationRegionId(postcodeData.postcode?.region?.id);
            setPrefectureText(postcodeData.postcode?.prefecture?.name);
            setLocationPrefectureId(postcodeData.postcode?.prefecture?.id);          
            setCityText(postcodeData.postcode?.city?.name);
            setLocationCityId(postcodeData.postcode?.city?.id);
            let textAreaKeyUp = postcodeData.postcode?.prefecture?.name + postcodeData.postcode?.city?.name;
            if(textAreaKeyUp) {
                setAreaText(textAreaKeyUp);
            }
        } 
    }, [postcodeData]);

    function onCheckEditCityCorrection(e: any) {
        let checkCityCorrection = e.target.checked;
        if(checkCityCorrection === false) {
            setisEditCityCorrection(false);
        } else {
            setisEditCityCorrection(true);
            setCityCorrection(cityText);
        }
    }      

    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    function handleChangetwo(event: any) {
        setBusinessContent(event);
    }

    function handleChangethree(event: any){
        setRequiredExperience(event);
    }

    function inputLocationDepartment(event: any) {
        setLocationDepartment(event);
    }

    function handleChangeAddress(event: any) {
        setlocationWorkLocationSupplemental(event);
    }

    function handleChangeContract(event: any) {
        setcontractPeriodSupplemental(event);
    }

    function handleChangetrial(event: any) {
        settrialPeriodSupplemental(event);
    }

    const handleChangework = (event: any) => {
        setworkTimeSupplemental(event.target.value);
    }

    const handleChangerest = (event: any) => {
        setrestTimeSupplemental(event.target.value);
    }

    const handleChangenumber = (event: any) => {
        setnumberOfWorkingDaysSupplemental(event.target.value);
    }

    const handleChangeovertime = (event: any) => {
        setovertimeHoursSupplemental(event.target.value);
    }

    const handleChangeholiday = (event: any) => {
        setholidaysSupplemental(event.target.value);
    }

    const handleChangeallowances = (event: any) => {
        setallowancesSupplemental(event.target.value);
    }

    const handleChangebenefit = (event: any) => {
        setbenefitSupplemental(event.target.value);
    }

    const handleChangeselection = (event: any) => {
        setselectionMethodSupplemental(event.target.value);
    }

    function handler_nationwide(e: any) {
        setWorkFromHomeId(e);
        if(parseInt(e) === 1) {
            setHideAddressDetail(false);
            setCheckCopyOnCompanyAddress(false);
            if(companies.company !== undefined) {
                company = companies.company;
                setAreaText(company.prefecture.name + company.city.name);
                setPrefectureText(company.prefecture.name);
                setCityText(company.city.name);
                setPostCode(company.postcode);
                setLocationAddress(company.address);
                setLocationRegionId(company.prefecture.region_id);
                setLocationPrefectureId(company.prefecture.id);
                setLocationCityId(company.city.id);
                
            }            
        } else {
            setHideAddressDetail(true);
            setCheckCopyOnCompanyAddress(false);
            setAreaText('');
            setPostCode('');
            setLocationAddress('');
            setLocationRegionId(null);
            setLocationPrefectureId(null);
            setLocationCityId(null);
        }
    }

    function handler_contractPeriodShow(e: any) {
        if(parseInt(e) === 1) {
            setIsContractPeriodShow(false);    
        } else {
            setIsContractPeriodShow(true);     
        }
        setSelectedEmploymentStatusId(e);
    }
    
    function handler_isContractPeriodExistsShow(e: any) {
        if(e.target.value === "true") {
            setIsContractPeriodExistsShow(true);    
        } else {
            setIsContractPeriodExistsShow(false);     
        }
    }

    function handler_isTrialPeriodExistsShow(e : any) {
        if(e.target.value === "true"){
            setisTrialPeriodExistsShow(true);
        } else{
            setisTrialPeriodExistsShow(false);
        }
    };

    const hourList = Array.from(Array(24), (_, i) => ('00' + i).slice(-2));
    const minuteList = Array.from(Array(12), (_, i) => ('00' + (i * 5)).slice(-2));

    function onChangeJobType(value: any) {
        setSelectedJobTypeId(value);
        setjobCategories(regions.job_categories.filter((category: any) => category.job_category_type_id == value));
    }

    function onChangeJobCategories(value: any) {
        setSelectedJobCategoryId(value);
    } 

    function onChangeRecCompany(value: any) {
        setTypeOfRecruitingCompanyId(parseInt(value));
    }

    function validates() {
        let result = ''        
        if (jobTitle == '') {
            result += '職業名\n'
        }
        if (selectedJobTypeId == null ||  selectedJobCategoryId == null) {
            result += '職種\n'
        }

        if (typeOfRecruitingCompanyId == null) {
            result += '募集する企業の区分\n'
        }

        if (companyName == '') {
            result += '会社名\n'
        }

        if (selectedEmploymentStatusId == '') {
            result += '雇用形態\n'
        }

        if (businessContent == '') {
            result += '業務内容\n'
        }

        if (workFromHomeId == null) {
          result += '働き方・テレワーク\n'
        }

        if (postCode == '' && locationAddress == '' || locationPrefectureId == null && locationCityId == null) { //住所            
            result += '住所\n'
        } 
        if(isContractPeriodShow) {
            if (isContractPeriodExistsShow === null) {
                result += '契約期間有無\n'
            }
        }        
        if (isTrialPeriodExistsShow === null) {
            result += '試用期間有無\n'
        }
        if (startTime1Hour === null && startTime1Minute === null) {
            result += '始業時間①\n'
        }
        if (endTime1Hour === null && endTime1Minute === null) {
            result += '終業時間①\n'
        }
        if (restTime === null) {
            result += '休憩時間\n'
        }
        if (!numberOfWorkingDays) {
            result += '勤務日数/週\n'
        }
        if (selectedHolidayIds === undefined) {
            result += '休日\n'
        }
        if (selectedSalaryFormId === '') {
            result += '給与方式\n'
        }
        if (lowerMonthlyBasicSalary === null || upperMonthlyBasicSalary === null) {
            result += '基本給\n'
        }
        if (annualNumberOfBonusExists === null) {
            result += '賞与\n'
            //set
        }
        if (parseInt(selectedSalaryFormId) == 1 && (lowerEstimatedAnnualIncome === null || upperMonthlyBasicSalary === null)) {
            result += '想定年収\n'
        }
        if (annualNumberOfRaisesExists === null) {
            result += '昇給\n'
        }
        if (selectedSocialInsuranceIds === undefined || selectedSocialInsuranceIds == null) {
            result += '社会保険\n'
        }
        if (retirementMoneyExists === null) {
            result += '退職金制度\n'
        }
        if (selectedSelectionMethodIds.length == 0 || selectedSelectionMethodIds == null) {
            result += '選考方法\n'
        }
        if (numberOfEmployees === null) {
            result += '想定採用人数\n'
        }    
        if (numberOfEmployees == 0) {
            result += '想定採用人数\n'
        }    
        
        return result
    }

    function onChangeLocationAddress(e: any) {
        setLocationAddress(e.target.value)
    }

    function onChangeLocationNearestStation(e: any) {
        setLocationNearestStation(e.target.value)
    }

    function onChangeLocationTransportation(e: any) {
        setLocationTransportation(e.target.value)
    }

    function onChangeNationwide(e: any) {
        if(e.target.value === 'true') {
            setNationwide(true);
        } else {
            setNationwide(false);
        }
    }

    function onKeyUpPostCode(e: any) {
        setPostCode(e.target.value);
        if ( e.target.value.length !== 7 ) { return }
        dispatch(fetchPostCodeRequest(e.target.value));
    }

    function handler_contractPeriods(e: any) {
        setSelectedContractPeriodId(e);
    }

    function OnChangeContractRenewalExists(e: any) {
        if(e.target.value === 'true') {
            setContractRenewalExists(true);
        } else {
            setContractRenewalExists(false);
        }
    } 

    function handler_trialPeriods(e: any) {
        if(e == '') {
            setSelectedTrialPeriodId(null);
        } else {
            setSelectedTrialPeriodId(e);
        }
    }
    
    function handler_fullTimeEmployeeExists(e: any) {
        if(e.target.value === 'true') {
            setFullTimeEmployeeExists(true);
        } else {
            setFullTimeEmployeeExists(false);
        }   
        
    } 

    function handler_overtimeHoursExists(e: any) {
        if(e.target.value === "true") {
            setOvertimeHoursExists(true);
        } else {
            setOvertimeHoursExists(false);
        }
        
    }

    function updateHour (v: any) {
        if("startTime1Hour" === v.target.name) {
            if (v.target.value == null || v.target.value == "") {
                setStartTime1Hour(null);
                setStartTime1Minute(null);
                setStartTime1(null)
            } else {
                setStartTime1Hour(v.target.value);
                setIsStartMinute1Disable(false);
                if(startTime1Minute !== null && startTime1Minute !== '') {
                    setStartTime1(v.target.value + ':' + startTime1Minute)
                } else {
                    setStartTime1(v.target.value + ':00');
                }
            }            
        }  
        
        if("startTime2Hour" === v.target.name) {
            if (v.target.value == null || v.target.value == "") {
                setStartTime2Hour(null);
                setStartTime2Minute(null);
                setStartTime2(null)
            } else {
                setStartTime2Hour(v.target.value);
                setIsStartMinute2Disable(false);
                if(startTime2Minute !== null && startTime2Minute !== '') {
                    setStartTime2(v.target.value + ':' + startTime2Minute)
                } else {
                    setStartTime2(v.target.value + ':00');
                }
            }            
        }

        if("startTime3Hour" === v.target.name) {
            if (v.target.value == null || v.target.value == "") {
                setStartTime3Hour(null);
                setStartTime3Minute(null);
                setStartTime3(null);
            } else {
                setStartTime3Hour(v.target.value);
                setIsStartMinute3Disable(false);
                if(startTime3Minute !== null && startTime3Minute !== '') {
                    setStartTime3(v.target.value + ':' + startTime3Minute)
                } else {
                    setStartTime3(v.target.value + ':00');
                }
            }            
        }

        if("endTime1Hour" === v.target.name) {
            if (v.target.value == null || v.target.value == "") {
                setEndTime1Hour(null);
                setEndTime1Minute(null);
                setEndTime1(null)
            } else {
                setEndTime1Hour(v.target.value);
                setIsEndMinute1Disable(false);
                if(endTime1Minute !== null && endTime1Minute !== '') {
                    setEndTime1(v.target.value + ':' + endTime1Minute)
                } else {
                    setEndTime1(v.target.value + ':00');
                }
            }            
        }  
        
        if("endTime2Hour" === v.target.name) {
            if (v.target.value == null || v.target.value == "") {
                setEndTime2Hour(null);
                setEndTime2Minute(null);
                setEndTime2(null)
            } else {
                setEndTime2Hour(v.target.value);
                setIsEndMinute2Disable(false);
                if(endTime2Minute !== null && endTime2Minute !== '') {
                    setEndTime2(v.target.value + ':' + endTime2Minute)
                } else {
                    setEndTime2(v.target.value + ':00');
                }
            }
        }

        if("endTime3Hour" === v.target.name) {
            if (v.target.value == null || v.target.value == "") {
                setEndTime3Hour(null);
                setEndTime3Minute(null);
                setEndTime3(null)
            } else {
                setEndTime3Hour(v.target.value);
                setIsEndMinute3Disable(false);
                if(endTime3Minute !== null && endTime3Minute !== '') {
                    setEndTime3(v.target.value + ':' + endTime3Minute)
                } else {
                    setEndTime3(v.target.value + ':00');
                }
            }            
        }
    }

    function updateMinute (v: any) {
        if("startTime1Minute" === v.target.name) {
            if (v.target.value == null || v.target.value == "" || startTime1Hour == null) {
                setStartTime1Hour(null);
                setStartTime1(null)
            } else {
                setStartTime1Minute(v.target.value);
                if(startTime1Hour !== null && startTime1Hour !== '') {
                    setStartTime1(startTime1Hour + ':' + v.target.value)
                }
            }            
        }

        if("startTime2Minute" === v.target.name) {
            if (v.target.value == null || v.target.value == "" || startTime2Hour == null) {
                setStartTime2Hour(null);
                setStartTime2(null)
            } else {
                setStartTime2Minute(v.target.value);
                if(startTime2Hour !== null && startTime2Hour !== '') {
                    setStartTime2(startTime2Hour + ':' + v.target.value)
                }
            }  
        }

        if("startTime3Minute" === v.target.name) {
            if (v.target.value == null || v.target.value == "" || startTime3Hour == null) {
                setStartTime3Hour(null);
                setStartTime3(null)
            } else {
                setStartTime3Minute(v.target.value);
                if(startTime3Hour !== null && startTime3Hour !== '') {
                    setStartTime3(startTime3Hour + ':' + v.target.value)
                }
            }  
        }

        if("endTime1Minute" === v.target.name) {
            if (v.target.value == null || v.target.value == "" || endTime1Hour == null) {
                setEndTime1Hour(null);
                setEndTime1(null)
            } else {
                setEndTime1Minute(v.target.value);
                if(endTime1Hour !== null && endTime1Hour !== '') {
                    setEndTime1(endTime1Hour + ':' + v.target.value)
                }
            }  
        }

        if("endTime2Minute" === v.target.name) {
            if (v.target.value == null || v.target.value == "" || endTime2Hour == null) {
                setEndTime2Hour(null);
                setEndTime2(null)
            } else {
                setEndTime2Minute(v.target.value);
                if(endTime2Hour !== null && endTime2Hour !== '') {
                    setEndTime2(endTime2Hour + ':' + v.target.value)
                }
            }  
        }

        if("endTime3Minute" === v.target.name) {
            if (v.target.value == null || v.target.value == "" || endTime3Hour == null) {
                setEndTime3Hour(null);
                setEndTime3(null)
            } else {
                setEndTime3Minute(v.target.value);
                if(endTime3Hour !== null && endTime3Hour !== '') {
                    setEndTime3(endTime3Hour + ':' + v.target.value)
                }
            }  
        }
    }

    function onChangeRestTime(e: any) {
        setRestTime(e.target.value);
    }

    function selectedWorkingDays(e: any) {
        setNumberOfWorkingDays(e.target.value);
    }

    function onChangeHolidays (e: any, index: any) {
        let c = [];
        for(let i = 0; i < selectedHolidayIds.length; i++) {
            c.push(selectedHolidayIds[i]);
        }
        let chk = false;
        for(let i = 0; i < c.length; i++) {
            if(c[i] == e.target.value) {
                chk = true;
                index = i;
            }
        }
        if(!chk) {
            c.push(parseInt(e.target.value));
        } else {
            c.splice(index, 1);
        }
        setSelectedHolidayIds(c);
    } 

    function onChangeAnnualHolidayCount (e: any) {
        setAnnualHolidayCount(e.target.value);
    } 

    function onChangeVacations (e: any, id: any) {
        let c = [];
        for(let i = 0; i < selectedVacationIds.length; i++) {
            c.push(selectedVacationIds[i]);
        }
        if(e.target.checked) {
            c.push(id);
        } else {
            c.splice(c.indexOf(id), 1);
        }
        setSelectedVacationIds(c);
    } 

    function onChangeSalaryForms (e: any) {
        setSelectedSalaryFormId(e.target.value);
        if(e.target.value === '1') {
            setIsAnnualIncomeShow(true)
        }else{
            setIsAnnualIncomeShow(false)
        }
    } 

    function onChangeLowerMonthlyBasicSalary (e: any) {
        setLowerMonthlyBasicSalary(e.target.value); 
    }

    function onChangeUpperMonthlyBasicSalary (e: any) {
        setUpperMonthlyBasicSalary(e.target.value);
    } 

    function onChangeAllowances (e: any, index: any) {
        // setSelectedAllowanceIds(e.target.value);
        let c = [];
        for(let i = 0; i < selectedAllowanceIds.length; i++) {
            c.push(selectedAllowanceIds[i]);
        }
        let chk = false;
        for(let i = 0; i < c.length; i++) {
            if(c[i] == index+1) {
                chk = true;
                index = i;
            }
        }
        if(!chk) {
            c.push(parseInt(index+1));
        } else {
            c.splice(index, 1);
        }
        setSelectedAllowanceIds(c);
    } 

    function onChangeAnnualNumberOfBonusExists (e: any) {
        if(e.target.value == 'true') {
            setAnnualNumberOfBonusExists(true);
        } else {
            setAnnualNumberOfBonusExists(false);
        } 
    } 

    function onChangeAnnualNumberOfBonus (e: any) {
        setAnnualNumberOfBonus(e.target.value)
    } 

    function onChangeLowerEstimatedAnnualIncome (e: any) {
        setLowerEstimatedAnnualIncome(e.target.value);
    }

    function onChangeUpperEstimatedAnnualIncome (e: any) {
        setUpperEstimatedAnnualIncome(e.target.value);
    } 

    function onChangeAnnualNumberOfRaisesExists (e: any) {
        if(e.target.value == 'true') {
            setAnnualNumberOfRaisesExists(true);
        } else {
            setAnnualNumberOfRaisesExists(false);
        } 
    } 

    function onChangeAnnualNumberOfRaises (e: any) {
        setAnnualNumberOfRaises(e.target.value);
    } 

    function onChangeSocialInsurances (e: any, index: any) {
        let c = [];
        for(let i = 0; i < selectedSocialInsuranceIds.length; i++) {
            c.push(selectedSocialInsuranceIds[i]);
        }
        let chk = false;
        for(let i = 0; i < c.length; i++) {
            if(c[i] == e.target.value) {
                chk = true;
                index = i;
            }
        }
        if(!chk) {
            c.push(parseInt(e.target.value));
        } else {
            c.splice(index, 1);
        }
        setSelectedSocialInsuranceIds(c);
    } 

    function onChangeRetirementMoneyExists (e: any) {
        if(e.target.value == 'true') {
            setRetirementMoneyExists(true);
        } else {
            setRetirementMoneyExists(false);
        }        
    } 

    function onChangeRetirementAge (e: any) {
        setRetirementAge(e.target.value);
    }

    function onChangeSelectionMethods (e: any, index: any) {
        let c = [];
        for(let i = 0; i < selectedSelectionMethodIds.length; i++) {
            c.push(selectedSelectionMethodIds[i]);
        }
        let chk = false;
        for(let i = 0; i < c.length; i++) {
            if(c[i] == e.target.value) {
                chk = true;
                index = i;
            }
        }
        if(!chk) {
            c.push(Number(e.target.value));
        } else {
            c.splice(index, 1);
        }
        setSelectedSelectionMethodIds(c);
    }

    function onChangeNumberOfEmployees (e: any) {
        setNumberOfEmployees(e.target.value);
    }    

    async function onClickMove() {   
        const validatedText = validates(); 
        if (validatedText) {
            alert('未入力項目があります\n\n' + validatedText)
            return
        }
        // let result = '';
        const params = {
            benefit_supplemental: benefitSupplemental,
            allowances: selectedAllowanceIds,
            company_id: companyId,
            job_title: jobTitle,
            job_category_id: parseInt(selectedJobCategoryId),
            job_category_type_id: parseInt(selectedJobTypeId),
            company_name: companyName,
            employment_status_id: selectedEmploymentStatusId,
            business_content: businessContent,
            holidays: selectedHolidayIds,
            period: {                
                contract_period_id: selectedContractPeriodId,
                contract_period_exists: isContractPeriodExistsShow,
                contract_renewal_exists: contractRenewalExists,
                full_time_employee_exists: fullTimeEmployeeExists,
                contract_period_supplemental: contractPeriodSupplemental,
                trial_period_exists: isTrialPeriodExistsShow,
                trial_period_id: selectedTrialPeriodId,
                trial_period_supplemental: trialPeriodSupplemental,
            },
            required_experience: requiredExperience,
            retirement_money_exists: retirementMoneyExists,
            retirement_age: retirementAge,
            salary : {
                annual_number_of_raises_exists: annualNumberOfRaisesExists,
                annual_number_of_raises: annualNumberOfRaises,
                allowances_supplemental: allowancesSupplemental,                
                annual_number_of_bonus_exists: annualNumberOfBonusExists,
                annual_number_of_bonus: annualNumberOfBonus,
                lower_monthly_basic_salary: lowerMonthlyBasicSalary,
                upper_monthly_basic_salary: upperMonthlyBasicSalary,
                lower_estimated_annual_income: lowerEstimatedAnnualIncome,
                upper_estimated_annual_income: upperEstimatedAnnualIncome, 
                salary_form_id: selectedSalaryFormId,
            },
            selection_method_supplemental: selectionMethodSupplemental,
            selection_methods: selectedSelectionMethodIds,
            social_insurances: selectedSocialInsuranceIds,
            target : {
                number_of_employees: numberOfEmployees
            },
            type_of_recruiting_company_id: typeOfRecruitingCompanyId,
            vacations: selectedVacationIds,        
            work_location: {
                address: locationAddress,
                city_correction: isEditCityCorrection ? cityCorrection : null,
                city_id: locationCityId,
                department: locationDepartment,
                nation_wide: nationwide,
                nearest_station: locationNearestStation,
                postcode: postCode,
                prefecture_id: locationPrefectureId,
                region_id: locationRegionId,
                transportation: locationTransportation,
                work_from_home_id: Number(workFromHomeId),
                work_location_supplemental: locationWorkLocationSupplemental,
            },
            work_time: {    
                annual_holiday: annualHolidayCount,   
                start_time1: startTime1,
                start_time2: startTime2,
                start_time3: startTime3,
                end_time1: endTime1,
                end_time2: endTime2,
                end_time3: endTime3,                
                work_time_supplemental: workTimeSupplemental,
                rest_time: restTime,
                rest_time_supplemental: restTimeSupplemental,
                number_of_working_days: parseInt(numberOfWorkingDays),
                number_Of_working_days_supplemental: numberOfWorkingDaysSupplemental,
                overtime_hours_exists: overtimeHoursExists,
                overtime_hours_supplemental: overtimeHoursSupplemental,
                holidays_supplemental: holidaysSupplemental,
            },
            recruitment_id: null,
        }
        setAllParams(params);
        setLoadScreen(true);
        setIsReload(true);
        dispatch(fetchRecruitmentsListRequest(id, 'put', params));        
    }

    useEffect(() => {
        if(recruitmentsData.pending && isReload) {
            setLoadScreen(false);
            if(recruitmentsData.recruitmentsList.id !== undefined) {
                params.recruitment_id = recruitmentsData.recruitmentsList.id;
                navigate('/recruiter/recruitments/create/step_2', {state: {params}});
                // window.location.reload();
            } else {
                window.alert('サーバーエラー');
            }
        } else if(recruitmentsData.error != null) {
            setIsReload(false);
            navigate('/error?code=500');
        }
    }, [recruitmentsData]);

    return (
        <main id="company">
          <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
          {loadScreen ? <Loader /> : <></>}
          <section> 
             <ul className="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
                <li><a href="#">求人管理</a></li>
            </ul>
            <div className="ttl_box">
              <h2 className="ttl">求人管理 ＞ 新規</h2>
              <ul>
                <li><a href="#" onClick={logout} ><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
              </ul>
            </div>
            <div className="jobs create3">
                <div className="step_box">
                    <ul>
                        <li className="active">基本情報</li>
                        <li>採用要件・アピール項目</li>
                    </ul>
                </div>
                <h3 className="ttl">求人基本情報</h3>
                {helloworkNotice ?
                <p>取り込んだデータの確認と修正をして「進む」を押してください。</p>
                : <></>}
                <form className="type2">
                    <div>
                        <dl>
                            <dt>職種名（20文字以内）<span>必須</span></dt>
                            <dd className="no_flex">
                                <input type="text" className="long" name="jobTitle" placeholder="例）経費に関するデータ入力業務" value={jobTitle || ''} onChange={(e) =>setJobTitle(e.target.value)}/>
                                {jobTitle.length > jobTitlemaxLength ? 
                                <div className="error_box">
                                    <p className="error_text">{jobTitle.length - jobTitlemaxLength}文字オーバー</p>
                                </div>
                                : <></>}
                            </dd>
                            <dt>職種<span>必須</span></dt>
                            <dd>
                                <select className="long" onChange={(e) => onChangeJobType(e.target.value)}>
                                    <option disabled selected value={'選択してください'}>選択してください</option>
                                    {jobCategoryTypes && jobCategoryTypes.map((jobCategoryType) => (
                                        <option selected={selectedJobTypeId !== null && (jobCategoryType.id === selectedJobTypeId ? jobCategoryType.name === jobCategoryType.name : jobCategoryType.name === "")} key={jobCategoryType.id} value={jobCategoryType.id}>
                                            {jobCategoryType.name}
                                        </option>
                                    ))}
                                </select>
                                <select className="long" onChange={(e) => onChangeJobCategories(e.target.value)}>
                                    <option disabled selected value={'選択してください'}>選択してください</option>
                                    {jobCategories && jobCategories.map((jobCategory: any) => (
                                        <option  selected={selectedJobCategoryId !== null && (jobCategory.id === selectedJobCategoryId ? jobCategory.name === jobCategory.name : jobCategory.name === "")} key={jobCategory.id} value={jobCategory.id}>
                                            {jobCategory.name}
                                        </option>
                                    ))}
                                </select>
                            </dd>
                            <dt>募集する企業の区分<span>必須</span></dt>
                            <dd>
                                <select className="long" onChange={(e) => onChangeRecCompany(e.target.value)}>
                                    <option disabled selected value={'選択してください'}>選択してください</option>
                                    {typeOfRecruitingCompanies && typeOfRecruitingCompanies.map((i) => (
                                        <option selected={typeOfRecruitingCompanyId !== null && (i.id === typeOfRecruitingCompanyId ? i.name === i.name : i.name === '')} key={i.id} value={i.id}>
                                            {i.name}
                                        </option>
                                    ))}
                                </select>
                            </dd>
                            <dt>企業名</dt>
                            <dd className="no_flex">
                                <input type="text" value={companyName} className="long" disabled/>
                            </dd>
                            <dt>雇用形態<span>必須</span></dt>
                            <dd>
                                <select className="long" onChange={(e) => handler_contractPeriodShow(e.target.value)}>
                                    <option disabled selected value={'選択してください'}>選択してください</option>
                                    {employmentStatuses && employmentStatuses.map((employmentStatuses) => (
                                        <option selected={selectedEmploymentStatusId != null && (employmentStatuses.id === selectedEmploymentStatusId ? employmentStatuses.name === employmentStatuses.name : employmentStatuses.name === "")} key={employmentStatuses.id} value={employmentStatuses.id}>
                                            {employmentStatuses.name}
                                        </option>
                                    ))}
                                </select>
                            </dd>
                            <dt>業務内容（400文字以内）<span>必須</span></dt>
                            <dd className="max">
                                <textarea  value={businessContent} onChange={(e) => handleChangetwo(e.target.value)} placeholder="◯◯（例：場所名・業種名など）で◯◯のお仕事です。
                                    【主な仕事内容】
                                    ・データ入力
                                    ・お客様からの問合せメールや電話の対応
                                    ・報告書などドキュメント類の作成">    
                                </textarea>
                                {businessContent.length > businessContentmaxLength ? 
                                <div className="error_box">
                                    <p className="error_text nomargin">{businessContent.length - businessContentmaxLength}文字オーバ</p>
                                </div>
                                : <></>}
                            </dd>
                            <dt>必要な経験・資格（200文字以内）</dt>
                            <dd className="max">
                                <textarea value={requiredExperience} onChange={(e) => handleChangethree(e.target.value)} placeholder="【必要な資格】特になし
                                    【求める経験/スキル】
                                    ・会社での事務・アシスタント経験
                                    ・PCスキル(Excel：簡単な関数を使えるレベル)
                                    【あると望ましい経験/スキル】
                                    ・人事アシスタント、経理など特定分野での事務職経験">
                                </textarea>
                                {requiredExperience.length > requiredExperiencemaxLength ? 
                                <div className="error_box">
                                    <p className="error_text  nomargin">{requiredExperience.length - requiredExperiencemaxLength}文字オーバ</p>
                                </div>
                                : <></>}
                            </dd>
                            <dt>住所(勤務地)</dt>
                            <dd>
                                <p className="dummy"></p>
                                <dl>
                                    <dt>働き方・テレワーク<span>必須</span></dt>
                                    <dd>
                                    {work_from_home && work_from_home.map((i) => (
                                        <label key={i.id}>
                                            {workFromHomeId != null && parseInt(workFromHomeId) == i.id ?
                                            <input type="radio" name="ohew" value={i.id} checked={true} onChange={(e) => handler_nationwide(e.target.value)}/>                                            
                                            :
                                            <input type="radio" name="ohew" value={i.id} checked={false} onChange={(e) => handler_nationwide(e.target.value)}/>}
                                            {i.name2}
                                        </label>
                                    ))}
                                    </dd>
                                    <dt>支店・部署名</dt>
                                    <dd><input type="text" value={locationDepartment !== null ? locationDepartment : ""} placeholder="本社、人事部など" onChange={(e) => inputLocationDepartment(e.target.value)}/></dd>
                                    {HideAddressDetail ?<dt v-show="hideAddressDetail">郵便番号<span>必須</span></dt> : <></>}
                                    {HideAddressDetail ?
                                    <dd>
                                        <input type="number"  v-model="postCode" onChange={onKeyUpPostCode} value={postCode}/>
                                        {/* onKeyUp={} */}
                                        <label><input type="checkbox" checked={checkCopyOnCompanyAddress} onChange={onCheckCopyOnCompanyAddress} />会社概要の住所と同一</label>
                                        <p className="note">※ハイフンは不要です。&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
                                    </dd>
                                    : <></>}
                                    {HideAddressDetail ?<dt v-show="hideAddressDetail">都道府県 市・区<span>必須</span></dt> : <></>}
                                    {HideAddressDetail ?
                                    <dd>
                                        {isEditCityCorrection ? 
                                        (<span  style={{ width: 300, marginRight: 10, display: 'flex', alignItems: 'center' }}>
                                            <p style={{ width:"90px", height: "40px", marginBottom: "0"}}>{prefectureText}</p>
                                            <input type="text" value={cityCorrection} onChange={onCityCorrection}></input>
                                        </span>
                                        ):(
                                            <p style={{ width: '300px', marginRight: '10px', marginBottom: "0"}}>{areaText}</p> 
                                        )}  
                                        {areaText.length > 0 ? 
                                            <label>
                                                <input type="checkbox" value={isEditCityCorrection} onChange={onCheckEditCityCorrection}/>市区町村を修正する
                                            </label>
                                        : <></>}
                                    </dd>
                                    // <dd>
                                    //     {isEditCityCorrection ? 
                                    //     <dd className="city">
                                    //     {!isEditCity ? <input v-if="!isEditCity" type="text" name="address0" value={disabled_address} v-model="disabled_address" disabled/> : <></>}
                                    //     {/* <input v-if="!isEditCity" type="text" name="address0" v-model="disabled_address" disabled/> */}
                                    //     {isEditCity ?<input v-if="isEditCity" type="text" name="prefecture" v-model="company.prefecture.name" value={prefecture_name} disabled/> : <></>}
                                    //     {isEditCity ?<input v-if="isEditCity" type="text" name="city" value={city_name} onChange={(e) => {setCityName(e.target.value)}}/> : <></>}
                                    //     <label>
                                    //       <input type="checkbox"  value={isEditCity} onChange={handleCheckboxChange}/>市区町村を修正する
                                    //     </label>
                                    //   </dd>
                                    //     : <></>}
                                    // </dd>
                                    : <></>}
                                    {HideAddressDetail ?<dt>住所（それ以降）</dt> : <></>}
                                    {HideAddressDetail ?<dd><input  type="text" value={locationAddress} onChange={onChangeLocationAddress}/></dd> : <></>}
                                    {HideAddressDetail ?<dt>最寄り駅</dt> : <></>}
                                    {HideAddressDetail ?<dd><input type="text" placeholder="東京メトロ半蔵門線 半蔵門駅など" value={locationNearestStation} onChange={onChangeLocationNearestStation}/></dd> : <></>}
                                    {HideAddressDetail ?<dt>最寄り駅からの交通手段</dt> : <></>}
                                    {HideAddressDetail ?<dd><input type="text" placeholder="徒歩5分など" value={locationTransportation} onChange={onChangeLocationTransportation}/></dd> : <></>}
                                    {HideAddressDetail ?<dt>転勤の有無</dt> : <></>}
                                    {HideAddressDetail ?
                                    <dd>
                                        <label><input type="radio" name="nationwide" value="true" checked={nationwide ? true : false} onChange={onChangeNationwide}/>有</label>
                                        <label><input type="radio" name="nationwide" value="false" checked={!nationwide ? true : false}  onChange={onChangeNationwide}/>無</label>
                                    </dd>
                                    : <></>}
                                    <dt>勤務地補足（200文字以内）</dt>
                                    <dd>
                                        <textarea placeholder={locationWorkLocationSupplementPlaceholder} value={locationWorkLocationSupplemental} onChange={(e) => handleChangeAddress(e.target.value)}></textarea>
                                        {locationWorkLocationSupplemental && locationWorkLocationSupplemental.length > locationWorkLocationSupplementalmaxLength ? 
                                            <div className="error_box">
                                                <p className="error_text  nomargin">{locationWorkLocationSupplemental.length - locationWorkLocationSupplementalmaxLength}文字オーバ</p>
                                            </div>
                                        : <></>}
                                    </dd>
                                </dl>
                            </dd>
                        </dl>
                    </div>
                    <div>
                        <h4>契約期間・試用期間</h4>
                        <dl>
                            {isContractPeriodShow ?<dt>契約期間</dt> : <></>}
                            {isContractPeriodShow && isData ?
                            <dd>
                                <p className="dummy"></p>
                                <dl>
                                    <dt>契約期間有無<span>必須</span></dt>
                                    <dd>
                                        <label><input type="radio" name="cpe" value="true" checked={isContractPeriodExistsShow ? true : false}  onChange={handler_isContractPeriodExistsShow}/>有</label>
                                        <label><input type="radio" name="cpe" value="false"  checked={!isContractPeriodExistsShow ? true : false}   onChange={handler_isContractPeriodExistsShow}/>無</label>
                                    
                                    {isContractPeriodExistsShow ? 
                                    <dl>
                                        <dt>契約期間</dt>
                                        <dd>
                                            <select onChange={(e) => handler_contractPeriods(e.target.value)}>
                                                <option selected value={'選択'}>選択</option>
                                                {contractPeriods && contractPeriods.map((contractPeriods) => (
                                                    <option selected={selectedContractPeriodId !== null && (contractPeriods.id === selectedContractPeriodId ? contractPeriods.name === contractPeriods.name : contractPeriods.name === "")} key={contractPeriods.id} value={contractPeriods.id}>
                                                        {contractPeriods.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <p className="note" style={{ width:"100%" }}>※選択項目の中に該当期間がない場合は、補足へ自由入力の形でご記入ください。</p>
                                        </dd>
                                        <dt>契約更新</dt>
                                        <dd> 
                                            <label><input type="radio" name="cre" value="true" checked={contractRenewalExists ? true : false} onChange={OnChangeContractRenewalExists}/>有</label>
                                            <label><input type="radio" name="cre" value="false"  checked={!contractRenewalExists ? true : false} onChange={OnChangeContractRenewalExists}/>無</label>
                                        </dd>
                                        <dt>その他補足（200文字以内）</dt>
                                        <dd>
                                            <textarea placeholder="補足があればご記入ください。"  value={contractPeriodSupplemental} onChange={(e) => handleChangeContract(e.target.value)}/>
                                            {contractPeriodSupplemental && contractPeriodSupplemental.length > contractPeriodSupplementalmaxLength ? 
                                            <div className="error_box">
                                                <p className="error_text  nomargin">{contractPeriodSupplemental.length - contractPeriodSupplementalmaxLength}文字オーバ</p>
                                            </div>
                                            : <></>}
                                        </dd>
                                    </dl>
                                    : <></>}
                                    </dd>
                                </dl>
                             </dd>
                             : <></>}

                            {isContractPeriodShow && !isData ?
                            <dd>
                                <p className="dummy"></p>
                                <dl>
                                    <dt>契約期間有無<span>必須</span></dt>
                                    <dd>
                                        <label><input type="radio" name="cpe" value="true" onChange={handler_isContractPeriodExistsShow}/>有</label>
                                        <label><input type="radio" name="cpe" value="false" onChange={handler_isContractPeriodExistsShow}/>無</label>
                                    
                                    {isContractPeriodExistsShow ? 
                                    <dl>
                                        <dt>契約期間</dt>
                                        <dd>
                                            <select onChange={(e) => handler_contractPeriods(e.target.value)}>
                                                <option selected value={'選択'}>選択</option>
                                                {contractPeriods && contractPeriods.map((contractPeriods) => (
                                                    <option selected={selectedContractPeriodId !== null && (contractPeriods.id === selectedContractPeriodId ? contractPeriods.name === contractPeriods.name : contractPeriods.name === "")} key={contractPeriods.id} value={contractPeriods.id}>
                                                        {contractPeriods.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <p className="note" style={{ width:"100%" }}>※選択項目の中に該当期間がない場合は、補足へ自由入力の形でご記入ください。</p>
                                        </dd>
                                        <dt>契約更新</dt>
                                        <dd> 
                                            <label><input type="radio" name="cre" value="true" checked={contractRenewalExists ? true : false} onChange={OnChangeContractRenewalExists}/>有</label>
                                            <label><input type="radio" name="cre" value="false"  checked={!contractRenewalExists ? true : false} onChange={OnChangeContractRenewalExists}/>無</label>
                                        </dd>
                                        <dt>その他補足（200文字以内）</dt>
                                        <dd>
                                            <textarea placeholder="補足があればご記入ください。"  value={contractPeriodSupplemental} onChange={(e) => handleChangeContract(e.target.value)}/>
                                            {contractPeriodSupplemental && contractPeriodSupplemental.length > contractPeriodSupplementalmaxLength ? 
                                            <div className="error_box">
                                                <p className="error_text  nomargin">{contractPeriodSupplemental.length - contractPeriodSupplementalmaxLength}文字オーバ</p>
                                            </div>
                                            : <></>}
                                        </dd>
                                    </dl>
                                    : <></>}
                                    </dd>
                                </dl>
                             </dd>
                             : <></>}

                            <dt>試用期間</dt>
                            <dd>
                                <p className="dummy"></p>
                                <dl>
                                    <dt>試用期間有無<span>必須</span></dt>
                                    {isData ?
                                    <dd>
                                        <label><input type="radio" name="tpe" checked={isTrialPeriodExistsShow ? true : false} value="true" onChange={handler_isTrialPeriodExistsShow}/>有</label>
                                        <label><input type="radio" name="tpe" checked={!isTrialPeriodExistsShow ? true : false} value="false" onChange={handler_isTrialPeriodExistsShow}/>無</label>
                                        {isTrialPeriodExistsShow ?
                                        <dl>
                                            <dt>試用期間</dt>
                                            <dd>
                                                <select onChange={(e) => handler_trialPeriods(e.target.value)}>
                                                    <option selected value="">選択なし</option>
                                                    {trialPeriods && trialPeriods.map((trialPeriods) => (
                                                    <option key={trialPeriods.id} value={trialPeriods.id} selected={selectedTrialPeriodId != null && (trialPeriods.id === selectedTrialPeriodId ? trialPeriods.name === trialPeriods.name : trialPeriods.name === "")}>
                                                        {trialPeriods.name}
                                                    </option>
                                                ))}
                                                </select>
                                                <p className="note" style={{ width:"100%" }}>※選択項目の中に該当期間がない場合は、補足へ自由入力の形でご記入ください。</p>
                                            </dd>
                                            <dt>その他補足（200文字以内）</dt>
                                            <dd>
                                                <textarea placeholder="補足があればご記入ください。" value={trialPeriodSupplemental} onChange={(e) => handleChangetrial(e.target.value)}/>
                                                {trialPeriodSupplemental && trialPeriodSupplemental.length > trialPeriodSupplementalmaxLength ? 
                                                <div className="error_box">
                                                    <p className="error_text  nomargin">{trialPeriodSupplemental.length - trialPeriodSupplementalmaxLength}文字オーバ</p>
                                                </div>
                                                : <></>}
                                            </dd>
                                        </dl>
                                        : <></>}
                                    </dd>
                                    :
                                    <dd>
                                        <label><input type="radio" name="tpe" value="true" onChange={handler_isTrialPeriodExistsShow}/>有</label>
                                        <label><input type="radio" name="tpe" value="false" onChange={handler_isTrialPeriodExistsShow}/>無</label>
                                        {isTrialPeriodExistsShow ?
                                        <dl>
                                            <dt>試用期間</dt>
                                            <dd>
                                                <select onChange={(e) => handler_trialPeriods(e.target.value)} >
                                                    <option selected value="">選択なし</option>
                                                    {trialPeriods && trialPeriods.map((trialPeriods) => (
                                                    <option key={trialPeriods.id} value={trialPeriods.id}>
                                                        {trialPeriods.name}
                                                    </option>
                                                ))}
                                                </select>
                                                <p className="note" style={{ width:"100%" }}>※選択項目の中に該当期間がない場合は、補足へ自由入力の形でご記入ください。</p>
                                            </dd>
                                            <dt>その他補足（200文字以内）</dt>
                                            <dd>
                                                <textarea placeholder="補足があればご記入ください。" value={trialPeriodSupplemental} onChange={(e) => handleChangetrial(e.target.value)}/>
                                                {trialPeriodSupplemental && trialPeriodSupplemental.length > trialPeriodSupplementalmaxLength ? 
                                                <div className="error_box">
                                                    <p className="error_text  nomargin">{trialPeriodSupplemental.length - trialPeriodSupplementalmaxLength}文字オーバ</p>
                                                </div>
                                                : <></>}
                                            </dd>
                                        </dl>
                                        : <></>}
                                    </dd>
                                    }
                                </dl>
                            </dd>
                            <dt>契約社員or正社員登用</dt>
                            { isData ?
                            <dd>
                                <p className="dummy"></p>
                                <dl>
                                    <dt>契約社員or正社員登用</dt>
                                    <dd>
                                        <label><input type="radio" name="ftee" value="true" checked={fullTimeEmployeeExists ? true : false} onChange={handler_fullTimeEmployeeExists}/>有</label>
                                        <label><input type="radio" name="ftee" value="false" checked={!fullTimeEmployeeExists ? true : false} onChange={handler_fullTimeEmployeeExists}/>無</label>
                                    </dd>
                                </dl>
                            </dd>
                            :
                            <dd>
                                <p className="dummy"></p>
                                <dl>
                                    <dt>契約社員or正社員登用</dt>
                                    <dd>
                                        <label><input type="radio" name="ftee" value="true" onChange={handler_fullTimeEmployeeExists}/>有</label>
                                        <label><input type="radio" name="ftee" value="false" onChange={handler_fullTimeEmployeeExists}/>無</label>
                                    </dd>
                                </dl>
                            </dd>
                            }
                        </dl>
                    </div>
                    <div>
                        <h4>勤務時間・休憩時間</h4>
                        <dl>
                            <dt>就業時間①<span>必須</span></dt>
                            <dd className="time_box">
                                <label>始業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="startTime1Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={startTime1Hour ? (h === startTime1Hour) : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)}  name="startTime1Minute" disabled={isStartMinute1Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={startTime1Minute ? (m === startTime1Minute) : m === ""} key={m} value={m}>
                                                    {m}
                                                 </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                                <p>〜</p>
                                <label>終業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="endTime1Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={endTime1Hour ? (h === endTime1Hour) : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)}  name="endTime1Minute" disabled={isEndMinute1Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={endTime1Minute ? (m === endTime1Minute) : m === ""} key={m} value={m}>
                                                    {m}
                                                 </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                            </dd>
                            <dt>就業時間②</dt>
                            <dd className="time_box">
                                <label>始業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="startTime2Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={startTime2Hour ? h === startTime2Hour : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="startTime2Minute" disabled={isStartMinute2Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={startTime2Minute ? m === startTime2Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                 </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                                <p>〜</p>
                                <label>終業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="endTime2Hour">
                                        <option selected value={''}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={endTime2Hour ? h === endTime2Hour : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="endTime2Minute" disabled={isEndMinute2Disable}>
                                        <option selected value={''}></option>
                                            {minuteList.map((m) => (
                                                <option selected={endTime2Minute ? m === endTime2Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                 </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                            </dd>
                            <dt>就業時間③</dt>
                            <dd className="time_box">
                                <label>始業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="startTime3Hour">
                                        <option value={" "}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={startTime3Hour ? h === startTime3Hour : h === ""} key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="startTime3Minute" disabled={isStartMinute3Disable}>
                                        <option value={" "}></option>
                                            {minuteList.map((m) => (
                                                <option selected={startTime3Minute ? m === startTime3Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                 </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                                <p>〜</p>
                                <label>終業時間
                                <div className="time_selector">
                                    <select onChange={(e) => updateHour(e)} name="endTime3Hour">
                                        <option value={" "}></option>
                                        {hourList && hourList.map((h) => (
                                            <option selected={endTime3Hour ? h === endTime3Hour : h === ""}  key={h} value={h}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                    <span>:</span>
                                    <select onChange={(e) => updateMinute(e)} name="endTime3Minute" disabled={isEndMinute3Disable}> 
                                        <option value={" "}></option>
                                            {minuteList.map((m) => (
                                                <option selected={endTime3Minute ? m === endTime3Minute : m === ""} key={m} value={m}>
                                                    {m}
                                                 </option>
                                        ))}
                                    </select>
                                    </div>
                                </label>
                                <dl>
                                    <dt>勤務時間補足（200文字以内）</dt>
                                    <dd>
                                        <textarea  value={workTimeSupplemental} onChange={handleChangework} placeholder="「毎週月曜日が朝礼のため8時45分出社」など。"></textarea>
                                        {workTimeSupplemental && workTimeSupplemental.length > workTimeSupplementalmaxLength ? 
                                        <div className="error_box">
                                            <p className="error_text">{workTimeSupplemental.length - workTimeSupplementalmaxLength}文字オーバ</p>
                                        </div>
                                        : <></>}
                                    </dd>
                                </dl>
                            </dd>
                            <dt>休憩時間<span>必須</span></dt>
                            <dd>
                                <input type="text" placeholder="45分" value={restTime ? restTime : ''} v-model="restTime" onChange={onChangeRestTime}/>
                                <dl>
                                    <dt>休憩時間補足（200文字以内）</dt>
                                    <dd>
                                        <textarea placeholder="「小休憩 10分」など。" value={restTimeSupplemental} onChange={handleChangerest}></textarea>
                                        {restTimeSupplemental && restTimeSupplemental.length >restTimeSupplementalmaxLength ? 
                                        <div className="error_box">
                                            <p className="error_text">{restTimeSupplemental.length - restTimeSupplementalmaxLength}文字オーバ</p>
                                        </div>
                                        : <></>}
                                    </dd>
                                </dl>
                            </dd>
                            <dt>勤務日数/週<span>必須</span></dt>
                            <dd>
                                <select onChange={selectedWorkingDays}>
                                    <option value={""}>選択</option>
                                    {workingDays.map((workingDay) => (
                                        <option selected={numberOfWorkingDays != null ? workingDay === numberOfWorkingDays : workingDay === " "} key={workingDay} value={workingDay}>
                                            {workingDay}
                                        </option>
                                    ))}
                                </select>
                                <p>日/週</p>
                                <dl>
                                    <dt>勤務日数補足（200文字以内）</dt>
                                    <dd>
                                        <textarea placeholder="「シフトに準ずる」など。" value={numberOfWorkingDaysSupplemental} onChange={handleChangenumber}></textarea>
                                        {numberOfWorkingDaysSupplemental.length >numberOfWorkingDaysSupplementalmaxLength ? 
                                        <div className="error_box">
                                            <p className="error_text">{numberOfWorkingDaysSupplemental.length - numberOfWorkingDaysSupplementalmaxLength}文字オーバ</p>
                                        </div>
                                        : <></>}
                                    </dd>
                                </dl>
                            </dd>
                            <dt>残業時間</dt>
                            {isData ?
                            <dd>
                                <label><input type="radio" name="ohe" value="true" checked={overtimeHoursExists ? true : false} onChange={handler_overtimeHoursExists}/>有</label>
                                <label><input type="radio" name="ohe" value="false" checked={!overtimeHoursExists ? true : false}  onChange={handler_overtimeHoursExists}/>無</label>
                            </dd>
                            :
                            <dd>
                                <label><input type="radio" name="ohe" value="true" onChange={handler_overtimeHoursExists}/>有</label>
                                <label><input type="radio" name="ohe" value="false" onChange={handler_overtimeHoursExists}/>無</label>
                            </dd>
                            }
                            <dt>残業時間補足（200文字以内）</dt>
                            <dd>
                                <textarea placeholder="平均残業時間15時間" value={overtimeHoursSupplemental} onChange={handleChangeovertime}></textarea>
                                {overtimeHoursSupplemental.length >overtimeHoursSupplementalmaxLength ? 
                                    <div className="error_box">
                                        <p className="error_text">{overtimeHoursSupplemental.length - overtimeHoursSupplementalmaxLength}文字オーバ</p>
                                    </div>
                                : <></>}
                            </dd>
                        </dl>
                    </div>
                    <div>
                        <h4>休日</h4>
                        <dl>
                            <dt>休日</dt>
                            <dd>
                            {holidays && holidays.map((holidays, index) => (
                                <label key={holidays.id}>
                                    <input  type="checkbox" checked={selectedHolidayIds.includes(holidays.id) ? true : false} value={holidays.id} onChange={(e) => onChangeHolidays(e, index)}></input>
                                    {holidays.name}
                                </label>
                            ))}
                            </dd>
                            <dt>年間休日</dt>
                            <dd>
                                <input type="number" className="small" value={annualHolidayCount !== null ? annualHolidayCount : ''} v-model="annualHolidayCount" onChange={onChangeAnnualHolidayCount}/>
                                <p>日</p>
                            </dd>
                            <dt>休日・休暇について</dt>
                            <dd>
                                <ul className="check_list">
                                {vacations && vacations.map((vacations) => (
                                            <li key={vacations.id}>
                                                <input  type="checkbox"  id={'vacation-' + vacations.id} value={vacations.id} checked={selectedVacationIds.includes(vacations.id)} onChange={(e) => onChangeVacations(e, vacations.id)}></input>
                                                <label htmlFor={'vacation-' + vacations.id}>{vacations.name}</label>
                                            </li>
                                        ))}
                                </ul>
                            </dd>
                            <dt>休日補足（200文字以内）</dt>
                            <dd>
                            <textarea placeholder="その他、特記事項があればご記入ください。" value={holidaysSupplemental} onChange={handleChangeholiday}></textarea>
                            {holidaysSupplemental && holidaysSupplemental.length > holidaysSupplementalmaxLength ? 
                                <div className="error_box">
                                    <p className="error_text nomargin">{holidaysSupplemental.length - holidaysSupplementalmaxLength}文字オーバ</p>
                                </div>
                                : <></>}
                            </dd>
                        </dl>

                    </div>
                    <div>
                        <h4>給与・賞与<span>※評価・業績により変動します。</span></h4>
                        <dl>
                            <dt>給与方式<span>必須</span></dt>
                            <dd>
                                <select onChange={onChangeSalaryForms}>
                                    <option>選択</option>
                                    {salaryForms && salaryForms.map((salaryForms) => (
                                        <option selected={selectedSalaryFormId !== null ? (salaryForms.id === selectedSalaryFormId ? salaryForms.name === salaryForms.name :  salaryForms.name === "") : salaryForms.name === ""} key={salaryForms.id} value={salaryForms.id}>
                                            {salaryForms.name}
                                        </option>
                                    ))}
                                </select>
                            </dd>
                            <dt>基本給<span>必須</span></dt>
                            <dd>
                                <input type="number" placeholder="250,000" maxLength={8} pattern="[0-9]*" value={lowerMonthlyBasicSalary != null ? lowerMonthlyBasicSalary : ''} onChange={onChangeLowerMonthlyBasicSalary}/>〜
                                <input type="number" placeholder="300,000" maxLength={8} pattern="[0-9]*" value={upperMonthlyBasicSalary != null ? upperMonthlyBasicSalary : ''} onChange={onChangeUpperMonthlyBasicSalary}/>
                            </dd>
                            <dt>各種手当・<br/>その他手当て</dt>
                            <dd>
                                <ul className="check_list">
                                        {allowances && allowances.map((allowances, index) => (
                                            <li key={allowances.id}>
                                                <input  type="checkbox" checked={selectedAllowanceIds.includes(allowances.id) ? true : false}  id={'allowance-' + allowances.id} onChange={(e) =>onChangeAllowances(e, index)}></input>
                                                <label htmlFor={'allowance-' + allowances.id}>{allowances.name}</label>
                                            </li>
                                        ))}
                                </ul>
                                <dl>
                                    <dt>各種手当補足（200文字以内）</dt>
                                    <dd>
                                        <textarea placeholder="「通勤手当: 5000円」など。" value={allowancesSupplemental} onChange={handleChangeallowances}></textarea>
                                        {allowancesSupplemental.length > allowancesSupplementalmaxLength ? 
                                        <div className="error_box">
                                            <p className="error_text nomargin">{allowancesSupplemental.length - allowancesSupplementalmaxLength}文字オーバ</p>
                                        </div>
                                        : <></>}
                                    </dd>
                                </dl>
                            </dd>
                            <dt>賞与<span>必須</span></dt>
                            {isData ?
                            <dd>
                                <select onChange={onChangeAnnualNumberOfBonusExists}>
                                    <option disabled selected>選択</option>
                                    <option value="true" selected={annualNumberOfBonusExists}>有</option>
                                    <option value="false" selected={!annualNumberOfBonusExists}>無</option>
                                </select>
                                <input type="text" value={annualNumberOfBonus !== null ? annualNumberOfBonus : ""} v-model="annualNumberOfBonus" onChange={onChangeAnnualNumberOfBonus}/>
                            </dd> 
                            :
                            <dd>
                                <select onChange={onChangeAnnualNumberOfBonusExists}>
                                    <option disabled selected>選択</option>
                                    <option value="true">有</option>
                                    <option value="false">無</option>
                                </select>
                                <input type="text" value={annualNumberOfBonus !== null ? annualNumberOfBonus : ""} v-model="annualNumberOfBonus" onChange={onChangeAnnualNumberOfBonus}/>
                            </dd> 
                            }
                            {isAnnualIncomeShow ?
                            <><dt>想定年収<span>必須</span></dt>
                            <dd>
                                <input type="number" placeholder="2,500,000" maxLength={8} pattern="[0-9]*" onChange={onChangeLowerEstimatedAnnualIncome}/>〜
                                <input type="number" placeholder="3,000,000" maxLength={8} pattern="[0-9]*" onChange={onChangeUpperEstimatedAnnualIncome}/>
                                <p className="note">例:月給×12ヶ月分+賞与+各種手当　※残業代除く</p>
                            </dd></>
                            : <></>}
                            <dt>昇給<span>必須</span></dt>
                            {isData ?
                            <dd>
                                <select onChange={onChangeAnnualNumberOfRaisesExists}>
                                    <option disabled selected>選択</option>
                                    <option value="true" selected={annualNumberOfRaisesExists}>有</option>
                                    <option value="false" selected={!annualNumberOfRaisesExists}>無</option>
                                </select>
                                <input type="text" v-model="annualNumberOfRaises" onChange={onChangeAnnualNumberOfRaises}/>
                            </dd>
                            :
                            <dd>
                                <select onChange={onChangeAnnualNumberOfRaisesExists}>
                                    <option disabled selected>選択</option>
                                    <option value="true">有</option>
                                    <option value="false">無</option>
                                </select>
                                <input type="text" v-model="annualNumberOfRaises" onChange={onChangeAnnualNumberOfRaises}/>
                            </dd>
                            }
                        </dl>
                    </div>
                    <div>
                        <h4>福利厚生</h4>
                        <dl>
                            <dt>社会保険</dt>
                            <dd>
                                {socialInsurances && socialInsurances.map((socialInsurances, index) => (
                                    <label key={socialInsurances.id}>
                                        <input  type="checkbox" value={socialInsurances.id} checked={selectedSocialInsuranceIds.includes(socialInsurances.id)} onChange={(e) =>onChangeSocialInsurances(e, index)}></input>
                                        {socialInsurances.name}
                                    </label>
                                ))}
                            </dd>
                            <dt>退職金制度<span>必須</span></dt>
                            {isData ?
                            <dd>
                                <label><input type="radio" name="ooh" value="true" checked={retirementMoneyExists ? true : false} onChange={onChangeRetirementMoneyExists}/>有</label>
                                <label><input type="radio" name="ooh" value="false" checked={!retirementMoneyExists ? true : false} onChange={onChangeRetirementMoneyExists}/>無</label>
                            </dd>
                            :
                            <dd>
                                <label><input type="radio" name="ooh" value="true" onChange={onChangeRetirementMoneyExists}/>有</label>
                                <label><input type="radio" name="ooh" value="false" onChange={onChangeRetirementMoneyExists}/>無</label>
                            </dd>
                            }
                            <dt>定年</dt>
                            <dd><input type="text" placeholder="60歳" value={retirementAge} onChange={onChangeRetirementAge}/></dd>
                            <dt>福利厚生補足（200文字以内）</dt>
                            <dd>
                                <textarea placeholder="「社員旅行」など" value={benefitSupplemental} onChange={handleChangebenefit}></textarea>
                                {benefitSupplemental && benefitSupplemental.length > benefitSupplementalmaxLength ? 
                                    <div className="error_box">
                                        <p className="error_text nomargin">{benefitSupplemental.length - benefitSupplementalmaxLength}文字オーバ</p>
                                    </div>
                                : <></>}
                            </dd>
                        </dl>
                    </div>
                    <div>
                        <h4>選考プロセス</h4>
                        <dl>
                            <dt>選考方法<span>必須</span></dt>
                            <dd>
                                {selectionMethods && selectionMethods.map((selectionMethods, index) => (
                                    <label key={selectionMethods.id}>
                                        <input  type="checkbox" checked={selectedSelectionMethodIds.includes(selectionMethods.id)} value={selectionMethods.id} onChange={(e) =>onChangeSelectionMethods(e, index)}></input>
                                        {selectionMethods.name}
                                    </label>
                                ))}
                            {/* <label v-for="selectionMethod in selectionMethods" ><input type="checkbox"  v-model="selectedSelectionMethodIds"/>{ selectionMethod.name }</label> */}
                            </dd>
                            <dt>その他特記事項（400文字以内）</dt>
                            <dd>
                                <textarea placeholder="その他、特記事項があればご記入ください。"value={selectionMethodSupplemental} onChange={handleChangeselection}></textarea>
                                {selectionMethodSupplemental.length > selectionMethodSupplementalmaxLength ? 
                                    <div className="error_box">
                                        <p className="error_text nomargin">{selectionMethodSupplemental.length - selectionMethodSupplementalmaxLength}文字オーバ</p>
                                    </div>
                                : <></>}
                            </dd>
                            <dt>想定採用人数<span>必須</span></dt>
                            <dd>
                                <input type="number" min="0" name="number_of_employees" placeholder="例）1" value={numberOfEmployees != null ? numberOfEmployees : ''} onChange={onChangeNumberOfEmployees}/> 人
                            </dd>
                        </dl>
                    </div>
                    <div className="btn_box">
                        <button type="button" onClick={onClickMove} className="btn type3 orange">進む<img src="/assets/img/jobs/arrow03.svg" alt=""/></button>
                    </div>
                </form>
            </div>
          </section>
        </main>
    )
}